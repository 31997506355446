import { brandName } from '@brand/config/brand-config'
import {
  ANDROID_APP_URL,
  getFooterLinks,
  IOS_APP_URL,
} from '@brand/config/footer-links'
import { Logo } from '@brand/slots/logo/logo'
import equalHousingDark from '@brand/static/images/equalhousing-dark.svg'
import equalHousing from '@brand/static/images/equalhousing.svg'
import clsx from 'clsx'
import Image from 'next/image'
import type { PageProps } from '../../../../app/types'
import { Anchor } from '../../../../components/anchor/anchor'
import { RentTerms } from '../../../../features/rent-terms/rent-terms'
import type { IPLocation } from '../../../../features/request-data/lib/ip-location/ip-location.types'
import { ScrollTrack } from '../../../../features/tagging/scroll-track'
import { FooterLinkSection } from './footer-link-section'
import { FooterSocialLinks } from '../../../../features/footer/footer-social-links'
import {
  COPYRIGHT,
  EQUAL_HOUSING_OPPORTUNITY_URL,
  FAIR_HOUSING_POLICY_URL,
  NEW_YORK_STATE_FAIR_HOUSING_NOTICE_URL,
} from '../../../../features/footer/footer.const'
import styles from './footer.module.css'
import type { FooterProps } from '../../../../features/footer/footer.types'
import { getFooterLocationData } from '../../../../features/footer/get-footer-location-data'

export function FooterCurrent({
  colorScheme = 'dark',
  ...props
}: FooterProps & {
  ipLocation?: IPLocation | null
  query: PageProps['searchParams']
}) {
  const { state, city, propertyType } = getFooterLocationData({
    ipLocation: props.ipLocation,
    query: props.query,
  })
  const footerLinks = getFooterLinks({
    state,
    city,
    propertyType,
  })

  return (
    <ScrollTrack
      as="footer"
      sectionName="scroll_depth"
      itemName="page_bottom"
      role="contentinfo"
      data-tag_section="footer"
      data-tid="footer"
      className={clsx(colorScheme === 'dark' && 'footerDark', styles.footer)}
    >
      <div className={styles.footerWrapper}>
        <div data-tid="logo" className={styles.footerLogoWrap}>
          <a href="/" title={brandName} aria-label={brandName}>
            <Logo className={styles.footerLogo} colorScheme="dark" />
          </a>
        </div>

        <FooterSocialLinks />

        <div className={styles.footerLinks}>
          {footerLinks.map((section) => {
            return (
              <FooterLinkSection
                key={section.heading}
                heading={section.heading}
                data-tid={section['data-tid']}
                links={section.links}
              />
            )
          })}
        </div>

        <div className={styles.footerInnerWrapper}>
          <div className={styles.footerApps}>
            {`Download the ${brandName} app for `}
            <Anchor
              href={ANDROID_APP_URL}
              target="_blank"
              rel="external noopener noreferrer"
              data-tag_item="android_download_button"
            >
              Android
            </Anchor>
            {' and '}
            <Anchor
              href={IOS_APP_URL}
              target="_blank"
              rel="external noopener noreferrer"
              data-tag_item="ios_download_button"
            >
              iOS
            </Anchor>
            .
          </div>
          <RentTerms
            termsTagId="terms_of_service_bottom"
            privacyPolicyTagId="privacy_policy_bottom"
            copyright={COPYRIGHT}
            className={styles.footerCopyright}
          />
          {props.disclaimer && (
            <p
              className={styles.footerDisclaimer}
              data-tid="footer-property-disclaimer"
            >
              Some of the materials or information displayed on this page may be
              subject to copyright protection by another party. To the extent
              such copyright rights exist, the following notice applies:{' '}
              {props.disclaimer}
            </p>
          )}{' '}
          If you are using a screen reader, or are having difficulty reading
          this website, please email{' '}
          <Anchor
            href="mailto:accessibilityfeedback@rent.com"
            data-tag_item="email_link_accessibility_feedback"
          >
            accessibilityfeedback@rent.com
          </Anchor>
          .
        </div>

        <div className={styles.footerEqualHousing}>
          <Anchor
            href={EQUAL_HOUSING_OPPORTUNITY_URL}
            data-tag_item="equal_housing"
            target="_blank"
            rel="noreferrer"
            title="Equal Housing Opportunity"
            className={styles.footerEqualHousingOpportunityAnchor}
          >
            <Image
              height={34}
              width={34}
              alt="Equal Housing Logo"
              aria-hidden="true"
              unoptimized
              src={colorScheme === 'dark' ? equalHousingDark : equalHousing}
            />
          </Anchor>
          <p className={styles.footerEqualHousingCopy}>
            Rent. is committed to and abides by the Fair Housing Act and Equal
            Opportunity Act. Read Rent.’s{' '}
            <Anchor
              href={FAIR_HOUSING_POLICY_URL}
              data-tag_item="fair_housing_policy"
              target="_blank"
              rel="noreferrer"
              className={styles.footerFairHousingAnchor}
            >
              Fair Housing Policy
            </Anchor>{' '}
            and{' '}
            <Anchor
              href={NEW_YORK_STATE_FAIR_HOUSING_NOTICE_URL}
              data-tag_item="new_york_state_fair_housing_notice"
              target="_blank"
              rel="noreferrer"
              className={styles.footerFairHousingAnchor}
            >
              The New York State Fair Housing Notice.
            </Anchor>
          </p>
        </div>
      </div>

      {props.adSlot}
    </ScrollTrack>
  )
}
