import type { ComponentProps } from 'react'
import { Anchor } from '../../../../components/anchor/anchor'
import { Heading } from '../../../../components/heading/heading'
import type { TestIdProp } from '../../../../types'
import styles from './footer-link-section-redesign.module.css'

export type FooterLink = {
  tag: string
  text: string
  url: string
  'data-tid'?: string
}

type FooterLinkSectionProps = {
  colorScheme?: ComponentProps<typeof Anchor>['variant']
  heading: string
  'data-tid'?: string
  links: FooterLink[]
} & TestIdProp

export function FooterLinkSection({
  heading,
  links,
  'data-tid': tid,
}: FooterLinkSectionProps) {
  return (
    <div data-tid={tid} className={styles.linkSection}>
      <Heading level="4x" as="span">
        <span className={styles.headingLabel}>{heading}</span>
      </Heading>

      <ul className={styles.list}>
        {links.map((link, linkIndex) => {
          return (
            <li key={`footer-link-${linkIndex}`} className={styles.listItem}>
              <Anchor
                href={link.url}
                data-tag_item={link.tag}
                data-tid={link['data-tid']}
              >
                {link.text}
              </Anchor>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
