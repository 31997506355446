import { socialLinksMap } from '@brand/config/social-links'
import styles from './footer-social-links.module.css'
import clsx from 'clsx'

type FooterSocialLinksProps = Record<string, unknown>

export function FooterSocialLinks({ isAgRedesign }: FooterSocialLinksProps) {
  return (
    <ul
      data-tid="social-links"
      className={clsx(
        styles.footerSocialLinks,
        // Once the AG Header Redesign is made permanent
        // Variables added here can be updated in the CSS module, and this can be removed
        // Or these could be split into their own slots
        Boolean(isAgRedesign) && styles.footerSocialLinksAgRedesign
      )}
    >
      {socialLinksMap.map((link, index) => {
        const Icon = link.icon
        return (
          <li
            key={`social-link-${index}`}
            className={styles.footerSocialLinkWrapper}
          >
            <a
              data-tag_item={link.tag}
              className={styles.footerSocialLinks}
              href={link.href}
              rel="external noopener noreferrer"
              target="_blank"
              title={link.alt}
            >
              <Icon aria-label={link.tag} className={styles.socialIcon} />
            </a>
          </li>
        )
      })}
    </ul>
  )
}
