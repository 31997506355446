import { PropertyTypeFilter } from '../../../__generated__/api-types'

export const INITIAL_SEARCH_FILTERS_COOKIE = 'initialSearchFilters'
export const LANDING_FILTERS_COOKIE = 'landingSearchFilters'
export const LANDING_FILTER_CATEGORIES_COOKIE = 'landingSearchFilterCategories'
export const APPLIED_FILTER_CATEGORIES_COOKIE = 'appliedFilterCategories'
export const ZIP_CODE_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/
export const defaultLocation = {
  city: 'louisville',
  state: 'kentucky',
} as const
export const apartmentPropertyTypeFilter = [PropertyTypeFilter.Apartments]
