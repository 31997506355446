import dynamic from 'next/dynamic'
import { ModalName } from './modals.config'
import { ModalLoadingIndicator } from './modal-loading-indicator'

export const modalDefinitions = {
  [ModalName.CONFIRM_DELETE_SAVED_SEARCH]: dynamic(() =>
    import(
      '../../features/search/saved-search/confirm-delete-saved-search-modal'
    ).then((mod) => mod.ConfirmDeleteSavedSearchModal)
  ),
  [ModalName.MARKETO_FORM]: dynamic(() =>
    import('../../features/list-a-property/marketo-form-modal').then(
      (mod) => mod.MarketoFormModal
    )
  ),
  [ModalName.EMAIL_CAPTURE]: dynamic(
    () =>
      import('../email-capture/email-capture-with-social-buttons-modal').then(
        (mod) => mod.EmailCaptureWithSocialButtonsModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.SRP_VIEWS_EMAIL_CAPTURE]: dynamic(
    () =>
      import('../email-capture/email-capture-modal').then(
        (mod) => mod.EmailCaptureModal
      ),
    {
      ssr: false,
    }
  ),
  [ModalName.LEAD_FORM]: dynamic(
    () =>
      import('../lead-form/lead-form-modal').then((mod) => mod.LeadFormModal),
    {
      ssr: false,
    }
  ),
  [ModalName.ONBOARDING]: dynamic(
    () => import('../onboarding/onboarding').then((mod) => mod.Onboarding),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.SIGN_IN]: dynamic(
    () =>
      import('../user/auth-modals/log-in-modal').then((mod) => mod.LogInModal),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.SIGN_UP]: dynamic(
    () =>
      import('../user/auth-modals/sign-up-modal').then(
        (mod) => mod.SignUpModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.FORGOT_PASSWORD]: dynamic(
    () =>
      import('../user/forgot-password/forgot-password-modal').then(
        (mod) => mod.ForgotPasswordModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  // [ModalName.THANK_YOU]: {
  //   resolve: () => import('src/features/lead-form/ThankYouModal'),
  // },
  [ModalName.REVIEW]: dynamic(
    () =>
      import(
        '../detail/ratings-reviews/review-modals/read-more/read-more'
      ).then((mod) => mod.ReadMoreReviewModal),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.REPORT_ABUSE]: dynamic(
    () =>
      import(
        '../detail/ratings-reviews/review-modals/report-abuse/report-abuse'
      ).then((mod) => mod.ReportAbuse),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.REQUEST_A_TOUR_WIZARD]: dynamic(
    () =>
      import('../request-a-tour/request-a-tour-wizard-modal').then(
        (mod) => mod.RequestTourWizardModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.REQUEST_A_TOUR_WIZARD_SIMPLE]: dynamic(
    () =>
      import('../request-a-tour/request-a-tour-wizard-modal').then(
        (mod) => mod.RequestTourWizardModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.THANK_YOU]: dynamic(
    () =>
      import('../thank-you/thank-you-modal').then((mod) => mod.ThankYouModal),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.TOUR_WIZARD]: dynamic(
    () =>
      import('../tour-wizard/tour-wizard-modal').then(
        (mod) => mod.TourWizardModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.VIDEO_TOURS]: dynamic(
    () =>
      import('../detail/tour-from-home/tour-from-home-modal').then(
        (mod) => mod.TourFromHomeModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.SHARE_PROPERTY]: dynamic(
    () => import('../detail/share-property/share-property.modal'),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),

  [ModalName.INCOME_RESTRICTIONS_CLARIFICATION]: dynamic(
    () =>
      import('../cta-buttons/clarification-modal/clarification-modal').then(
        (mod) => mod.ClarificationModal
      ),
    {
      ssr: false,
    }
  ),
  [ModalName.SAVED_SEARCH_CONFIRMATION_MODAL]: dynamic(
    () =>
      import('../search/saved-search/saved-search-confirmation-modal').then(
        (mod) => mod.SavedSearchConfirmationModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.SAVED_SEARCH_SIGN_UP_MODAL]: dynamic(
    () =>
      import('../user/auth-modals/saved-search-sign-up-modal').then(
        (mod) => mod.SavedSearchSignUpModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
  [ModalName.SAVED_SEARCH_LOG_IN_MODAL]: dynamic(
    () =>
      import('../user/auth-modals/saved-search-log-in-modal').then(
        (mod) => mod.SavedSearchLogInModal
      ),
    {
      ssr: false,
      loading: ModalLoadingIndicator,
    }
  ),
} as const
