'use client'

import { useFeatureVariable } from '@rentpath/ab-testing-react'
import type { IPLocation } from '../../../../features/request-data/lib/ip-location/ip-location.types'
import type { PageProps } from '../../../../app/types'
import { FooterCurrent } from './footer-current'
import { FooterRedesign } from './footer-redesign'
import type { FooterProps } from '../../../../features/footer/footer.types'

export function FooterShared(
  props: FooterProps & {
    ipLocation?: IPLocation | null
    query: PageProps['searchParams']
  }
) {
  const isAgRedesign =
    useFeatureVariable<number>(['ag_header_redesign', 'version'], 0) > 0

  return isAgRedesign ? (
    <FooterRedesign
      {...props}
      ipLocation={props.ipLocation}
      query={props.query}
    />
  ) : (
    <FooterCurrent
      {...props}
      ipLocation={props.ipLocation}
      query={props.query}
    />
  )
}
