import type { PageProps } from '../../app/types'
import type { IPLocation } from '../request-data/lib/ip-location/ip-location.types'
import { defaultLocation } from '../search/const/search.const'

const defaultPropertyType = 'apartments'

export function getFooterLocationData({
  ipLocation,
  query,
}: {
  ipLocation?: IPLocation | null
  query: PageProps['searchParams']
}) {
  const { state: defaultState, city: defaultCity } = defaultLocation

  let state: string | null = null
  let city: string | null = null

  const queryCity = query?.city || query?.location

  if (
    queryCity &&
    typeof queryCity === 'string' &&
    query.state &&
    typeof query.state === 'string'
  ) {
    state = query.state
    city = queryCity
  }

  if ((!city || !state) && ipLocation) {
    state = ipLocation.state || null
    city = ipLocation.city || null
  }

  if (!city || !state) {
    city = defaultCity
    state = defaultState
  }

  const propertyTypeSlug = query?.['property-type'] || query?.propertyTypes
  const propertyType = getPropertyType(propertyTypeSlug)

  return { state, city, propertyType }
}

function getPropertyType(propertyType?: string | string[]) {
  if (!propertyType) return defaultPropertyType
  if (propertyType?.includes('_')) return defaultPropertyType
  if (propertyType?.includes('-')) return defaultPropertyType

  return propertyType
}
