import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

export function useScrollTrack(sectionName: string, itemName: string) {
  const [intersectionRef, inView] = useInView({
    triggerOnce: true,
  })

  useEffect(() => {
    if (inView) {
      window.eventTracker?.track('scroll', {
        item: itemName,
        non_interaction: true,
        section: sectionName,
      })
    }
  }, [inView, itemName, sectionName])

  return intersectionRef
}
