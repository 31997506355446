import { ReactComponent as FacebookIcon } from '../icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg'
import { ReactComponent as PinterestIcon } from '../icons/pinterest.svg'
import { ReactComponent as XIcon } from '../icons/x.svg'
import { ReactComponent as YoutubeIcon } from '../icons/youtube.svg'

const links = {
  facebook: 'https://www.facebook.com/apartmentguide',
  x: 'https://x.com/apartmentguide',
  pinterest: 'https://www.pinterest.com/apartmentguide',
  youtube: 'https://www.youtube.com/apartmentguide',
  instagram: 'https://instagram.com/apartmentguide',
}

interface Link {
  alt: string
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  href: string
  tag: string
}

export const socialLinksMap: Link[] = [
  {
    alt: 'Facebook',
    href: links.facebook,
    icon: FacebookIcon,
    tag: 'facebook',
  },
  {
    alt: 'X',
    href: links.x,
    icon: XIcon,
    tag: 'x',
  },
  {
    alt: 'Pinterest',
    href: links.pinterest,
    icon: PinterestIcon,
    tag: 'pinterest',
  },
  {
    alt: 'YouTube',
    href: links.youtube,
    icon: YoutubeIcon,
    tag: 'youtube',
  },
  {
    alt: 'Instagram',
    href: links.instagram,
    icon: InstagramIcon,
    tag: 'instagram',
  },
]
